import PropTypes from "prop-types";

import {
  CCard,
  CCardBody,
  CCardHeader,
  CCardLink,
  CCardTitle,
} from "@coreui/react";

import "@coreui/coreui/dist/css/coreui.min.css";

function MenuCard({ menuItem }) {
  return (
    <div>
      <CCard>
        <CCardBody>
          <CCardHeader>{menuItem.title}</CCardHeader>
          <CCardTitle>{menuItem.subtitle}</CCardTitle>
          {menuItem.links.map((link) => (
            <div key={link.url}>
              <CCardLink href={link.url} target="_blank">
                {link.text}
              </CCardLink>
              <br />
            </div>
          ))}
        </CCardBody>
      </CCard>
    </div>
  );
}

MenuCard.propTypes = {
  menuItem: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
  }),
};

export default MenuCard;
