import PropTypes from "prop-types";

import {
  CCard,
  CCardBody,
  CCardHeader,
  CCardLink,
  CCardSubtitle,
  CCardText,
  CCardTitle,
} from "@coreui/react";

import "@coreui/coreui/dist/css/coreui.min.css";

function EventCard({ event }) {
  return (
    <div>
      <CCard className="w-80">
        <CCardBody>
          <CCardHeader>
            {event.title && (
              <CCardTitle>
                <div className="text-center">
                  {event.icon && (
                    <img src={event.icon} alt={event.icon} className="w-10" />
                  )}
                  {event.title}
                </div>
              </CCardTitle>
            )}
            {event.subtitle && (
              <CCardSubtitle className="m-2 text-body-secondary text-center">
                {event.subtitle}
              </CCardSubtitle>
            )}
          </CCardHeader>
          {event.time?.start && (
            <div className="text-body-primary text-center">
              {event.time.start}
            </div>
          )}
          {(event.time?.duration || event.place) && (
            <div className="text-body-secondary text-center">
              Durata: {event.time?.duration}
              <br />
              Luogo: {event.place}
            </div>
          )}
          <CCardText>{event.text}</CCardText>
          {event.links.map((link) => (
            <div key={link.url}>
              <CCardLink className="text-purple-600 underline" href={link.url}>
                {link.text}
              </CCardLink>
              <br />
            </div>
          ))}
        </CCardBody>
      </CCard>
    </div>
  );
}

EventCard.propTypes = {
  event: PropTypes.shape({
    time: PropTypes.shape({
      start: PropTypes.string,
      duration: PropTypes.string,
    }),
    icon: PropTypes.string,
    place: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};

export default EventCard;
