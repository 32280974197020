import { useState } from "react";
import Calendar from "react-calendar";
import EventCard from "./components/EventCard";
import "./MenottiApp.css";

async function getEvents() {
  const response = await fetch("data/events3A.json");
  return await response.json();
}

const events = await getEvents();

const zeroPad = (num, places) => String(num).padStart(places, "0");

const eventsGroupedByDay = events.reduce((acc, event) => {
  const day = event.time.start.split(" ")[0];
  if (!acc[day]) {
    acc[day] = [];
  }
  acc[day].push(event);
  return acc;
}, {});

const fixedEvent = {
  title: "Link utili",
  links: [
    {
      url: "https://icsoliera.edu.it/avvio-anno-scolastico-2024-25/",
      text: "Avvio Anno Scolastico 2024/25",
    },
    {
      url: "https://icsoliera.edu.it/wp-content/uploads/sites/847/Calendario-scolastico-2024-25.pdf?x16776",
      text: "Calendario Scolastico",
    },
    {
      url: "https://classroom.google.com/",
      text: "Classroom",
    },
    {
      url: "https://menottilimidi.wordpress.com/",
      text: "Blog Menotti",
    },
    {
      url: "https://docs.google.com/spreadsheets/d/1dbTwj5LplfBDCaUzySGrLuzaXMKioE9_s1jtJMUliz8/edit?usp=sharing",
      text: "Raccolte Soldi",
    },
  ],
};

function formatDate(date) {
  return [
    date.getYear() + 1900,
    zeroPad(date.getMonth() + 1, 2),
    zeroPad(date.getDate(), 2),
  ].join("-");
}

function MenottiApp() {
  const [value, setValue] = useState(new Date());
  const [selectedEvents, setSelectedEvents] = useState([]);

  function fetchTileClassName(data) {
    const date = data.date;
    const today = new Date();

    const isToday = date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);

    let backgroundFormat =
      date.getTime() < today.getTime() ? "bg-gray-400" : "bg-sky-200";

    if (isToday) {
      backgroundFormat = "bg-green-400";
    }

    // Controllare se ci sono eventi quel giorno
    let hasEvents = false;

    const formattedDate = formatDate(date);

    if (formattedDate in eventsGroupedByDay) {
      hasEvents = eventsGroupedByDay[formattedDate].length > 0;
    }

    const todayFormat = isToday ? " font-bold text-green-800" : "";

    let textFormat = hasEvents
      ? " font-bold italic text-blue-800 text-xl"
      : todayFormat;

    return backgroundFormat + "" + textFormat;
  }

  function fetchTileContent(data) {
    const date = data.date;
    const formattedDate = formatDate(date);

    console.log("-----> " + formattedDate);

    if (formattedDate in eventsGroupedByDay) {
      return eventsGroupedByDay[formattedDate].map((event) => {
        console.log(event);

        return (
          event?.icon && (
            <div>
              <img src={event.icon} alt={event.icon} className="w-4" />
            </div>
          )
        );
      });
    }

    return null;
  }

  function onClickDay(data) {
    const date = formatDate(data);

    if (date in eventsGroupedByDay) {
      console.log(eventsGroupedByDay[date]);
      setSelectedEvents(eventsGroupedByDay[date]);
    } else {
      setSelectedEvents([]);
    }
  }

  return (
    <div className="m-4 flex flex-col grid justify-items-center">
      <div>
        <EventCard event={fixedEvent} />
      </div>
      <br />
      <Calendar
        onChange={setValue}
        onClickDay={onClickDay}
        value={value}
        tileContent={fetchTileContent}
        tileClassName={fetchTileClassName}
        showDoubleView={false}
        showNeighboringMonth={false}
        showFixedNumberOfWeeks={false}
      />
      <br />
      <div>
        {selectedEvents?.map((event) => (
          <EventCard key={event.title} event={event} />
        ))}
      </div>
    </div>
  );
}

export default MenottiApp;
