import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MenottiApp from "./MenottiApp";
import HomeApp from "./HomeApp";
import CMCalendarApp from "./CMCalendarApp";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeApp />} />
        <Route path="/menotti" element={<MenottiApp />} />
        <Route path="/cm_calendar" element={<CMCalendarApp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
