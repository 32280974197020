import "./App.css";

import MenuCard from "./components/MenuCard";

async function getMenu() {
  const response = await fetch("data/menu.json");
  return await response.json();
}

const menu = await getMenu();

function HomeApp() {
  return (
    <div className="m-4 flex justify-around grid grid-cols-[repeat(auto-fill,23rem)] gap-4">
      {menu.map((menuItem) => (
        <MenuCard key={menuItem.title} menuItem={menuItem} />
      ))}
    </div>
  );
}

export default HomeApp;
